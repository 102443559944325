import { TypeOfStep } from "models/step/answer/answer.model";
import { StepI } from "../../generateStepModel";

export const step_1: StepI = {
  isNeccesary: true,
  indexOfStep: 1,
  indexOfStepToGoTo: 2,
  typeOfStep: TypeOfStep.SelectList,
  question: 'W jakiej dzielnicy Rybnika mieszkasz?',
  answers: [
    'Boguszowice Osiedle.',
    'Boguszowice Stare.',
    'Chwałęcice.',
    'Chwałowice.',
    'Golejów.',
    'Gotartowice.',
    'Grabownia.',
    'Kamień.',
    'Kłokocin.',
    'Ligota-Ligocka Kuźnia.',
    'Maroko-Nowiny.',
    'Meksyk.',
    'Niedobczyce.',
    'Niewiadom.',
    'Ochojec.',
    'Orzepowice.',
    'Paruszowiec-Piaski.',
    'Popielów.',
    'Radziejów.',
    'Rybnicka Kuźnia.',
    'Rybnik-Północ.',
    'Smolna.',
    'Stodoły.',
    'Śródmieście.',
    'Wielopole.',
    'Zamysłów.',
    'Zebrzydowice.',
    {answer: 'Nie mieszkam w Rybniku.', indexOfStepToGoTo: 200},
  ],
}